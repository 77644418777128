import { useContext } from "react";
import { Container } from "shared/components/Container";
import AppContext from "shared/contexts/AppContext";
import HomeContext from "shared/contexts/HomeContext";
import * as S from "./styled";

export function Header() {
  const { handleScrollTo } = useContext(HomeContext);
  const { menuOpened, setMenuOpened } = useContext(AppContext);

  return (
    <S.Section opened={menuOpened}>
      <Container align="center">
        <S.Logo />
        <S.NavWrap>
          <S.Nav>
            <S.NavItem onClick={() => handleScrollTo("solution")}>
              Solution
            </S.NavItem>
            <S.NavItem onClick={() => handleScrollTo("keys")}>Keys</S.NavItem>
            <S.NavItem onClick={() => handleScrollTo("technology")}>
              Technology
            </S.NavItem>
            <S.NavItem onClick={() => handleScrollTo("customers")}>
              Customers
            </S.NavItem>
            <S.NavItem onClick={() => handleScrollTo("exchanges")}>
              Exchanges
            </S.NavItem>
            <S.NavItem onClick={() => handleScrollTo("faq")}>FAQ</S.NavItem>
          </S.Nav>
          <a href="https://ca.multik.io/signup">
            <S.StyledButton onClick={() => handleScrollTo("getstarted")}>
              Get started
            </S.StyledButton>
          </a>
          <S.MenuToggle onClick={() => setMenuOpened(!menuOpened)}>
            {menuOpened ? <S.IconClose /> : <S.IconMenu />}
          </S.MenuToggle>
        </S.NavWrap>
      </Container>
    </S.Section>
  );
}
