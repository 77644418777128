import { useState } from "react";
import { Container } from "shared/components/Container";
import * as S from "./styled";
import { Button } from "shared/components/Button";

const FAQ = () => {
  const [isActivePanel, setActivePanel] = useState({});
  const toggleItem = (index) => {
    setActivePanel((prevState) => ({
      ...prevState,
      [index]: !Boolean(prevState[index]),
    }));
  };

  const faq = [
    [
      "Is my cryptocurrency safe if something happens to the Multik service? How can I ensure the security of my funds in such a scenario?",
      "Absolutely, your funds are completely secure even if there are issues with the Multik service. At Multik, we prioritize your security by never collecting private keys from your wallets. This means you retain full control over your assets at all times. In the unlikely event of a service disruption, you can easily transfer your funds directly through the exchange or simply disable the API keys. Rest assured, safeguarding your financial security is our top priority.",
    ],
    [
      "How does Multik manage and secure API keys, and what measures are in place to ensure the security of my cryptocurrency assets?",
      "Multik ensures the security of your cryptocurrency assets by implementing robust measures for managing API keys. These keys are stored in secure storage via a secured remote connection, and you can easily add them by scanning a QR code with the Multik provisioning app. This process ensures that your keys are never exposed or stored in plain text. Additionally, Multik never collects private keys from your wallets. All sensitive information, including API keys, is protected by Intel SGX technology, which provides an isolated memory area that safeguards your data from unauthorized access, ensuring the highest level of security for your assets.",
    ],
    [
      "Which cryptocurrency exchanges does Multik support, and can I connect multiple accounts simultaneously?",
      "Multik supports more than 10 leading cryptocurrency exchanges, including Binance, Bitfinex, Bitstamp, ByBit, Deribit, Kraken, DyDx, KuCoin, HitBTC, and OKX. You can connect accounts from these exchanges simultaneously. This allows you to view a consolidated overview of all your assets in one place, simplifying the management of your crypto portfolio and ensuring full control over your digital assets.",
    ],
    [
      "What subscription plans does Multik offer, and what features are included in each",
      `Multik offers enterprise-grade security at a retail price in two subscription plans designed to meet the diverse needs of our users:<br/>
Advanced Plan: Priced at $250 per month (discounted to $100 if you subscribe until the end of the year), this plan includes the ability to connect up to 5 accounts, access for 3 users, cloud hosting, and using secure Intel® SGX Enclave technology for data protection. You will receive all the essential features of the platform for effective management of your crypto assets in one place.
<br/>Enterprise Plan: This plan is tailored specifically to your requirements. It includes advanced settings such as customisation of limits and thresholds, an unlimited number of accounts and users in your workspace, the option to host the platform on your own servers, and other additional features.
<br/>To learn more about the Enterprise Plan and its pricing, please contact our sales team at sales@multuk.io or fill out the form during the registration process.`,
    ],
    [
      "How does Multik ensure the security of my transactions and prevent unauthorized fund transfers?",
      `At Multik, we prioritize the security of your transactions by employing a multilayered approach to protection.
<br/><br/>
First Line of Defense: We utilize Intel® SGX Enclave technology. This technology creates a secure and isolated memory area (enclave) where all critical operations and data, including signing withdrawal requests, are processed in a secure environment. This ensures that your confidential information is protected from unauthorized access, even from the operating system or privileged software.
<br/>
Second Line of Defense: Our flexible Policy Engine allows you to configure limits and thresholds for users and accounts, create account pools, and set quorum requirements for transaction approvals. Additionally, transfers are only possible to pre-approved (whitelisted) addresses, preventing funds from being sent to unauthorized or suspicious destinations.
<br/>This combined approach ensures maximum security for your funds and gives you full control over the management of your crypto assets.`,
    ],
    [
      "What analytics and reporting capabilities does the Multik platform provide?",
      `Multik offers user-friendly tools for analytics and detailed reporting on your transactions and trading positions. But the capabilities don't stop there. Users can also take advantage of our integration with back-office systems, which allows you to record and process transactions in a convenient and structured format. This simplifies internal accounting and financial information management, providing complete transparency and control over your operations. With these tools, you can effectively monitor financial activity, make informed decisions, and easily prepare necessary reports.`,
    ],
  ];

  return (
    <S.Section id="faq">
      <Container>
        <S.Title>Frequently Asked Questions</S.Title>
        <S.Wrap>
          {faq.map((item, index) => {
            return (
              <S.Block>
                <S.Q
                  className={isActivePanel[index] ? "opened" : ""}
                  onClick={() => toggleItem(index)}
                >
                  <div>{item[0]}</div>
                  {isActivePanel[index] ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.4853 11.9999H3.51472"
                        stroke="#B9BEC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.4853 11.9999H3.51472"
                        stroke="#B9BEC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 3.51465V20.4852"
                        stroke="#B9BEC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                </S.Q>
                <S.A
                  dangerouslySetInnerHTML={{ __html: item[1] }}
                  className={isActivePanel[index] ? "opened" : ""}
                ></S.A>
              </S.Block>
            );
          })}
        </S.Wrap>

        <S.ButtonWrap>
          <a href="https://ca.multik.io/signup">
            <Button>Get started</Button>
          </a>
        </S.ButtonWrap>
      </Container>
    </S.Section>
  );
};

export default FAQ;
