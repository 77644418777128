import Device from "device";
import styled from "styled-components";

export const Section = styled.section`
  width: 100%;
  max-width: 100%;
  position: relative;
  padding: 128px 0 0 0;
  user-select: none;
  position: relative;

  @media ${Device.laptop} {
    padding: 160px 0 0 0;
  }
`;
export const Title = styled.h2`
  text-align: center;
  margin-bottom: 40px;
`;

export const Wrap = styled.div`
  display: flex;
  align-items: baseline;
  position: relative;
  overflow: hidden;
  justify-content: space-between;
  width: 100%;
`;

export const Tabs = styled.div`
  column-gap: 3rem;
  white-space: nowrap;
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0px;
  }

  @media ${Device.laptop} {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

export const Tab = styled.h3`
  font-size: 110%;
  color: #80899d;
  cursor: pointer;
  padding-bottom: 4px;

  &.active {
    color: #ffffff;
    border-bottom: 2px solid #0064ff;
  }

  display: inline-block;
  margin-right: 40px;

  @media ${Device.laptop} {
    display: block;
  }
`;

export const Blocks = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  line-height: 1.75rem;
  width: 100%;
`;

export const Block = styled.div`
  margin: 1rem 1.8rem;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  @media only screen and (min-width: 600px) {
    width: 40%;
  }

  @media only screen and (min-width: 992px) {
    width: 28%;
  }
`;

export const SubTitle = styled.h4`
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

export const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 40px 0 0 0;
`;
