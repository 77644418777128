import Device from "device";
import styled from "styled-components";

export const Section = styled.section`
  width: 100%;
  max-width: 100%;
  position: relative;
  padding: 128px 0 0 0;
  user-select: none;
  position: relative;

  @media ${Device.laptop} {
    padding: 160px 0 0 0;
  }
`;
export const Title = styled.h2`
  text-align: left;
  margin-bottom: 40px;
`;
export const SubTitle = styled.h3`
  text-align: left;
  font-size: 150%;
`

export const Wrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
`;

export const Block = styled.div`
  width: 100%;
  line-height: 1.75rem;
`

export const Q = styled.div`
  font-weight: bold;
  padding: 2rem 0 1.5rem 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  &.opened {

  }
`

export const A = styled.div`
  display: none;

  &.opened {
    display: inline;
  }
`

export const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 40px 0 0 0;
`;
