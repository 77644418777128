import React, { useContext, useEffect, useState } from "react";
import { Container } from "shared/components/Container";
import HomeContext from "shared/contexts/HomeContext";
import * as S from "./styled";

const Exchanges = () => {
  const { scrollPosition } = useContext(HomeContext);
  const [position, setPosition] = useState(scrollPosition || 0);

  useEffect(() => {
    setPosition(scrollPosition / 5);
  }, [scrollPosition]);

  return (
    <S.Section id="exchanges">
      <Container>
        <S.Title>Supported exchanges and&nbsp;assets</S.Title>
        <S.Items>
          <S.Item>
            <S.IconCheck />
            <S.ItemText>
              Track your portfolio, open orders and positions
            </S.ItemText>
          </S.Item>
          <S.Item>
            <S.IconCheck />
            <S.ItemText>
              Transfer any asset between exchange&nbsp;accounts on 20+
              blockchains
            </S.ItemText>
          </S.Item>
          <S.Item>
            <S.IconCheck />
            <S.ItemText>
              Withdraw and deposit using your connected blockchain wallets
            </S.ItemText>
          </S.Item>
          <S.Item>
            <S.IconCheck />
            <S.ItemText>Work with 100+ tokens and NFTs</S.ItemText>
          </S.Item>
        </S.Items>
      </Container>
      <S.ExchangesWrap>
        <S.ExchangesTop style={{ backgroundPosition: position }} />
        <S.ExchangesBottom style={{ backgroundPosition: -position }} />
      </S.ExchangesWrap>
      {/* <Container>
        <S.ButtonWrap>
          <Button onClick={() => handleScrollTo("getstarted")}>
            Get started
          </Button>
        </S.ButtonWrap>
      </Container> */}
      <S.Triangle />
      <S.Triangle2 />
    </S.Section>
  );
};

export default Exchanges;
