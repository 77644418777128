import Device from "device";
import { ReactComponent as SvgLock } from "shared/assets/icons/icon-lock.svg";
import styled from "styled-components";

export const Section = styled.section`
  width: 100%;
  max-width: 100%;
  position: relative;
  padding: 128px 0 0 0;
  user-select: none;
  position: relative;
  margin-bottom: 0px;

  @media ${Device.laptop} {
    padding: 160px 0 0 0;
    margin-bottom: 300px;
  }
`;
export const Title = styled.h2`
  text-align: center;
  // margin-bottom: -30px;
`;
export const SubTitle = styled.h3`
  text-align: left;
  font-size: 32px;
  line-height: 48px;
`;

export const Wrap = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;
export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  grid-auto-rows: auto;
  width: 100%;
  margin: 40px 0 40px 0;
  position: relative;
  flex: 1;
`;

export const ListItem = styled.div``;
export const ListItemText = styled.div`
  margin: 16px 0 0 0;
`;
export const IconLock = styled(SvgLock)`
  width: 24px;
  height: 24px;
`;
export const Pic = styled.picture`
  display: none;
  overflow: hidden;

  img {
    width: 100%;
  }

  @media ${Device.laptop} {
    display: block;
    width: 100%;
    padding-top: 1rem;
  }
`;
export const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 40px 0 0 0;
`;

export const TLine = styled.div`
  width: 100%;
  display: flex;
  column-gap: 10%;
`;

export const TText = styled.div`
  width: 100%;
  line-height: 1.75rem;
`;

export const TPic = styled.div`
  width: 100%;
  position: relative;
`;

export const TGrid = styled.div`
  margin-top: 20px;
  width: 100%;
  position: relative;
  display: grid;
  grid-gap: 120px;
  grid-template-columns: 100% 0;
  grid-template-rows: 1fr;
  align-items: center;

  @media ${Device.laptop} {
    margin-top: 80px;
    grid-template-columns: 1fr 1fr;
  }
`;

export const TGridSwapped = styled.div`
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: 0 1fr;
  grid-template-rows: 1fr;
  align-items: center;

  @media ${Device.laptop} {
    margin-top: 80px;
    grid-template-columns: 1.23fr 1fr;
  }
`;

export const FlexNumbers = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  // align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 20px;

  @media ${Device.tablet} {
    flex-direction: row;
    margin-top: 100px;
    margin-bottom: 60px;
    height: unset;
  }
`;

export const FlexNumbersItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 284px;
`;

export const FlexNumbersItemNumber = styled.span`
  font-size: 2.5rem;
  font-family: "Lato";
  font-weight: bold;
`;

export const FlexNumbersItemText = styled.span`
  font-size: 1.25rem;
  font-family: "Lato";
`;
