import { useContext } from "react";
import { Button } from "shared/components/Button";
import { Container } from "shared/components/Container";
import HomeContext from "shared/contexts/HomeContext";
import * as S from "./styled";

const Solution = () => {
  const { handleScrollTo } = useContext(HomeContext);
  return (
    <S.Section id="solution">
      <Container>
        <S.Wrap>
          <S.Left>
            <S.Title>
              All your team<span style={{ color: "#c91cff" }}>'</span>s crypto
              <br />
              at a glance
            </S.Title>
            <S.Items>
              <S.Item>
                <S.IconLayers />
                <S.ItemText>
                  Store and transact over 100 different tokens from one screen
                </S.ItemText>
              </S.Item>
              <S.Item>
                <S.IconKey />
                <S.ItemText>
                  Enable secure operations without sharing passwords or keys
                </S.ItemText>
              </S.Item>
              <S.Item>
                <S.IconCopy />
                <S.ItemText>
                  Eliminate the need to copy-paste addresses daily
                </S.ItemText>
              </S.Item>
              <S.Item>
                <S.IconGroup />
                <S.ItemText>
                  Empower your team to operate within approved guidelines,
                  minimizing risks
                </S.ItemText>
              </S.Item>
            </S.Items>
            <a href="https://ca.multik.io/signup">
              <Button onClick={() => handleScrollTo("getstarted")}>
                Get Started
              </Button>
            </a>
          </S.Left>
          <S.Pic>
            <img
              srcSet="/i/mockup-imac.png, /i/mockup-imac@2x.png 2x"
              src="/i/mockup-imac.png"
              alt="Multik"
            />
          </S.Pic>
        </S.Wrap>
      </Container>
      <S.Triangles />
    </S.Section>
  );
};

export default Solution;
